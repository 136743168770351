// extracted by mini-css-extract-plugin
export var author = "blog-post-module--author--RBq+m";
export var authorBlock = "blog-post-module--author-block--gbBuQ";
export var authorImage = "blog-post-module--author-image--Ja-fS";
export var back = "blog-post-module--back--W7kFN";
export var content = "blog-post-module--content--d8slE";
export var date = "blog-post-module--date--fETgi";
export var embeddedForm = "blog-post-module--embedded-form--d+OiG";
export var featureImage = "blog-post-module--feature-image--rlDkY";
export var intro = "blog-post-module--intro--sN-Fr";
export var metaBlock = "blog-post-module--meta-block--52pRZ";
export var post = "blog-post-module--post--u0eOx";
export var postTitle = "blog-post-module--post-title--V0tRO";
export var readTime = "blog-post-module--read-time--bmRLP";
export var seoHeading = "blog-post-module--seo-heading--DViX0";
export var shareBlock = "blog-post-module--share-block--WOpWk";
export var shareLinksVertical = "blog-post-module--share-links-vertical--oLRSg";
export var socialIcon = "blog-post-module--social-icon--SpG-b";
export var socialLinks = "blog-post-module--social-links--v2PKX";